import type { ClassNameProps } from '@/types/common.types';

const ToggleNextSVG = ({ className }: ClassNameProps) => {
  return (
    <svg viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M18 7.5359C20.6667 9.0755 20.6667 12.9245 18 14.4641L6 21.3923C3.33333 22.9319 0 21.0074 0 17.9282V4.07179C0 0.992592 3.33333 -0.931905 6 0.607696L18 7.5359Z"
        fill="white"
      />
    </svg>
  );
};

export default ToggleNextSVG;
