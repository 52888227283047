'use client';

import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';

import ToggleNextSVG from '@/icons/ToggleNextSVG';
import TogglePrevSVG from '@/icons/TogglePrevSVG';

import type { Banner } from '@/api/types/home.types';

import { Swiper, SwiperClass, NavigationOptions, SwiperSlide } from '@/components/Slider/Swiper';

import { BannerSlide } from '@/views/HomeView/BannerSlider/BannerSlide';
import styles from '@/views/HomeView/BannerSlider/BannerSlider.module.scss';

// =================================================================

interface BannerSliderProps {
  banners: Banner[];
}

// =================================================================

export const BannerSlider = (props: BannerSliderProps) => {
  const { banners } = props;

  const previousSlideButtonRef = useRef<HTMLButtonElement | null>(null);
  const nextSlideButtonRef = useRef<HTMLButtonElement | null>(null);

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const handleSwiperInit = (swiper: SwiperClass) => setSwiper(swiper);

  useEffect(() => {
    if (swiper) {
      if (swiper.params.navigation) {
        const navigation = swiper.params.navigation as NavigationOptions;
        navigation.prevEl = previousSlideButtonRef.current;
        navigation.nextEl = nextSlideButtonRef.current;
        swiper.navigation?.update();
      }
    }
  }, [swiper]);

  return (
    <div className={styles.bannerWrapper}>
      <Container>
        <Swiper
          loop
          wrapperTag="ul"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: previousSlideButtonRef.current,
            nextEl: nextSlideButtonRef.current,
          }}
          mousewheel={{ forceToAxis: true }}
          onInit={handleSwiperInit}
        >
          {banners.map((banner: Banner) => (
            <SwiperSlide key={banner.id} tag="li">
              <BannerSlide banner={banner} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>

      <button ref={previousSlideButtonRef} className={styles.bannerTogglePrev}>
        <div className={styles.toggleIconWrapper}>
          <TogglePrevSVG className={styles.toggleIcon} />
        </div>
      </button>
      <button ref={nextSlideButtonRef} className={styles.bannerToggleNext}>
        <div className={styles.toggleIconWrapper}>
          <ToggleNextSVG className={styles.toggleIcon} />
        </div>
      </button>
    </div>
  );
};
