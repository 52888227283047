import type { SVGProps } from '@/types/common.types';

const FatChevronSVG = (props: SVGProps) => {
  return (
    <svg viewBox="0 0 9 11" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.848 4.70315C8.51349 5.16067 8.50054 6.14761 7.82327 6.58751L2.36603 10.1321C1.61206 10.6218 0.615371 10.0807 0.615371 9.18164V1.88522C0.615371 0.972679 1.6388 0.434322 2.39077 0.9513L7.848 4.70315Z" />
    </svg>
  );
};

export default FatChevronSVG;
