'use client';

import { useTranslations } from 'next-intl';

import { useHomeGenres } from '@/views/HomeView/HomeGenres/useHomeGenres';

import { HomeContentBaseSlider } from '@/views/HomeView/HomeContentBaseSlider';
import { HomeGenreCard } from '@/views/HomeView/HomeGenres/HomeGenreCard';
import { HomeGenreCardSkelton } from '@/views/HomeView/HomeGenres/HomeGenreCardSkelton';
import styles from '@/views/HomeView/HomeGenres/HomeGenres.module.scss';

export const HomeGenres = () => {
  const t = useTranslations('page.home');

  const { genres, isLoading } = useHomeGenres();

  return (
    <HomeContentBaseSlider
      sm={3}
      md={4}
      lg={5}
      xl={7}
      heading={t('genres')}
      className={styles.homeGenresSlider}
      slideClassName={styles.homeGenreSlide}
    >
      {isLoading
        ? Array(10)
            .fill(null)
            .map((_, index) => <HomeGenreCardSkelton key={index} />)
        : genres.map(genre => <HomeGenreCard key={genre.genre_id} genre={genre} />)}
      {!isLoading && <HomeGenreCard genre={genres[0]} />}
    </HomeContentBaseSlider>
  );
};
