import { useQuery } from '@tanstack/react-query';

import { useLocale } from 'next-intl';

import { Locale } from '@/types/common.types';

import { HomeApi } from '@/api/domains/home.api';

import { HomeQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useHomeGenres = () => {
  const locale = useLocale() as Locale;

  const { data = [], isLoading } = useQuery({
    queryKey: HomeQueryKeys.homeGenres(locale),
    queryFn: HomeApi.fetchHomeGenres,
  });

  return { genres: data, isLoading };
};
