'use client';

import { forwardRef } from 'react';

import { useTranslations } from 'next-intl';

import { SolidButton } from '@/components/Buttons';

interface LoaderButtonProps {
  isLoading: boolean;
  onClick: () => void;
}

export const LoaderButton = forwardRef<HTMLButtonElement, LoaderButtonProps>((props, ref) => {
  const t = useTranslations('common');
  const { isLoading, onClick } = props;

  return (
    <SolidButton as="button" ref={ref} size="full" disabled={isLoading} onClick={onClick}>
      {isLoading ? `${t('loading')}...` : t('showMore')}
    </SolidButton>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  LoaderButton.displayName = 'LoaderButton';
}
