'use client';

import { useRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import clsx from 'clsx';

import styles from '@/views/HomeView/HomeGenres/HomeGenreCard.module.scss';

export const HomeGenreCardSkelton = () => {
  const boxRef = useRef<HTMLDivElement | null>(null);

  return (
    <div ref={boxRef} className={clsx(styles.homeGenreCard, styles.isSkelton)}>
      <div className={styles.genreIcon}>
        <div style={{ paddingBottom: '100%', width: '100%' }} />
      </div>
      <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={90}>
        <Skeleton className={styles.genreSkelton} />
      </SkeletonTheme>
    </div>
  );
};
