import type { ClassNameProps } from '@/types/common.types';

const TogglePrevSVG = ({ className }: ClassNameProps) => {
  return (
    <svg viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M2 14.4641C-0.66667 12.9245 -0.666667 9.0755 2 7.5359L14 0.607693C16.6667 -0.931908 20 0.992596 20 4.0718V17.9282C20 21.0074 16.6667 22.9319 14 21.3923L2 14.4641Z"
        fill="white"
      />
    </svg>
  );
};

export default TogglePrevSVG;
