'use client';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { breakpoints } from '@/app.config';
import { useRouter, usePathname } from '@/navigation';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import type { OnlineTvList } from '@/api/types/home.types';

import { Badge } from '@/components/Badge';
import { Image } from '@/components/Image';

import styles from '@/views/HomeView/OnlineTvList/OnlineTvCard.module.scss';

interface OnlineTvCardProps {
  channel: OnlineTvList;
  isAuthenticated: boolean;
}

export const OnlineTvCard = (props: OnlineTvCardProps) => {
  const { channel, isAuthenticated } = props;
  const { isFree, isHd, channelIcon, title, id, moduleId } = channel;

  const router = useRouter();
  const pathname = usePathname();
  const t = useTranslations('verification');

  const {
    checkAuthVerification,
    checkSubscriptionVerification,
    setSubscriptionTitle,
    closeVerificationModal,
  } = useVerification();

  const handleChannelCardClick = checkAuthVerification({
    onSuccess: () => {
      if (isFree) {
        router.push(`/online-tv?channelId=${id}&autoplay=true`);
        closeVerificationModal();
      } else {
        checkSubscriptionVerification({
          onMountFn: () => {
            // TODO : this title should be sent by the backend
            const subscriptionTitle =
              moduleId === 32
                ? t('subscriptionTitleSport', { channel: title })
                : t('subscriptionTitle', { channel: title });
            setSubscriptionTitle(subscriptionTitle);
            // ----------------------------------------------------------------

            router.push(`${pathname}?moduleId=${moduleId}`);
          },
          onSuccess: () => {
            router.push(`/online-tv?channelId=${id}&autoplay=true`);
          },
        });
      }
    },
  });

  return (
    <button
      type="button"
      onClick={handleChannelCardClick}
      className={clsx({
        [styles.onlineTvCard]: true,
        [styles.isLocked]: !isFree && isAuthenticated,
      })}
    >
      <div className={styles.cardInner}>
        <Image
          aspectRatio={1 / 1}
          src={channelIcon}
          alt={title}
          fill
          sizes={`33vw, ${breakpoints.small} 25vw, ${breakpoints.medium} 17vw, ${breakpoints.large} 12.5vw`}
        />
        {isHd && (
          <div className={styles.badgeContainer}>
            <Badge variant="quality" position="right">
              HD
            </Badge>
          </div>
        )}
      </div>
    </button>
  );
};
