'use client';

import { MouseEventHandler } from 'react';

import clsx from 'clsx';

import { breakpoints } from '@/app.config';
import CloseSVG from '@/icons/CloseSVG';

import { useCardRemove } from '@/components/MovieCard/useCardRemove';
import { useInvalidateUnfinishedMovies } from '@/views/HomeView/UnfinishedMovieList/useUnfinishedMovies';

import type { UnfinishedMovie } from '@/api/types/home.types';

import { FadeTransition } from '@/components/Animations';
import { IconButton } from '@/components/Buttons';
import {
  useCardHover,
  CardBody,
  CardImage,
  CardOverlay,
  CardSubtitle,
  CardTitle,
} from '@/components/Card';
import { ForwardedLink } from '@/components/ForwardedLink';
import { ProgressBar } from '@/components/ProgressBar';

import styles from '@/views/HomeView/UnfinishedMovieList/UnfinishedMovieCard.module.scss';

// =================================================================

const CARD_ASPECT_RATIO = 210 / 118;

// =================================================================

interface UnfinishedMovieCardProps {
  movie: UnfinishedMovie;
}

// =================================================================

export const UnfinishedMovieCard = (props: UnfinishedMovieCardProps) => {
  const { movie } = props;
  const {
    duration,
    moduleId,
    movieId,
    fileId,
    lastPosition,
    isWatched,
    title,
    snapshot,
    season,
    movieTitle,
    isSerial,
  } = movie;

  const { ref, isHovered } = useCardHover<HTMLDivElement>();
  const { isRemoving, remove } = useCardRemove({
    deepRemove: false,
  });

  const onRemoved = useInvalidateUnfinishedMovies(movieId);

  const handleRemove: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    remove({
      moduleId,
      movieId,
      onRemoved,
    });
  };

  return (
    <div ref={ref} className={styles.cardWrapper}>
      <ForwardedLink
        href={
          `/watch/${moduleId}/${movieId}` +
          `?fileId=${fileId}` +
          '&autoplay=true' +
          (isWatched ? '&lastPosition=0' : `&lastPosition=${lastPosition}`)
        }
        className={styles.card}
      >
        <CardImage
          as="div"
          aspectRatio={CARD_ASPECT_RATIO}
          src={snapshot}
          alt={movieTitle}
          className={clsx({
            [styles.cardImgHovered]: isHovered,
          })}
          sizes={`50vw, ${breakpoints.small} 33vw, ${breakpoints.medium} 25vw, ${breakpoints.large} 20vw`}
        >
          <FadeTransition inProp={isHovered}>
            <CardOverlay />
          </FadeTransition>
          <ProgressBar
            max={duration}
            value={isWatched ? duration : lastPosition}
            variant="signature"
            className={styles.progressbar}
          />
        </CardImage>
        <CardBody>
          <CardTitle>{movieTitle}</CardTitle>
          {isSerial && (
            <CardSubtitle>
              {season} {title}
            </CardSubtitle>
          )}
        </CardBody>
        <FadeTransition inProp={isHovered}>
          <IconButton
            as="button"
            icon={CloseSVG}
            iconWidth={18}
            iconHeight={18}
            title="Удалить из истории"
            onClick={handleRemove}
            disabled={isRemoving}
            className={styles.trashbinIcon}
          />
        </FadeTransition>
      </ForwardedLink>
    </div>
  );
};
