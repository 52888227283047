'use client';

import { Container } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useTranslations } from 'next-intl';

import { useUnfinishedMovies } from '@/views/HomeView/UnfinishedMovieList/useUnfinishedMovies';

import { Slider } from '@/components/Slider';

import { UnfinishedMovieCard } from '@/views/HomeView/UnfinishedMovieList/UnfinishedMovieCard';
import { UnfinishedMovieCardSkelton } from '@/views/HomeView/UnfinishedMovieList/UnfinishedMovieCardSkelton';
import styles from '@/views/HomeView/UnfinishedMovieList/UnfinishedMovieList.module.scss';

export const UnfinishedMovieList = () => {
  const t = useTranslations('page.home');

  const { movies, isEnable, status } = useUnfinishedMovies();

  if (status === 'pending' && isEnable) {
    return (
      <Container className="mb-5 p-0">
        <Slider
          sm={2}
          md={3}
          lg={4}
          xl={5}
          heading={
            <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={240}>
              <Skeleton className={styles.genreSkelton} />
            </SkeletonTheme>
          }
          slideClassName={styles.unfinishedMovieSlide}
        >
          {Array(10)
            .fill(null)
            .map((_, index) => (
              <UnfinishedMovieCardSkelton key={index} />
            ))}
        </Slider>
      </Container>
    );
  }

  if (movies.length === 0 || !isEnable) {
    return null;
  }

  return (
    <Container className="mb-5 p-0">
      <Slider
        sm={2}
        md={3}
        lg={4}
        xl={5}
        heading={t('continueWatch')}
        slideClassName={styles.unfinishedMovieSlide}
      >
        {movies.map(movie => (
          <UnfinishedMovieCard key={movie.id} movie={movie} />
        ))}
      </Slider>
    </Container>
  );
};
