'use client';

import React, { useRef, ReactNode } from 'react';

import cn from 'classnames';

import FatChevronSVG from '@/icons/FatChevronSVG';
import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import { IconButton } from '@/components/Buttons/IconButton';
import { SectionHeading } from '@/components/SectionHeading';
import styles from '@/components/Slider/Slider.module.scss';
import { Swiper, SwiperSlide } from '@/components/Slider/Swiper';

// =================================================================

export interface SliderProps extends ClassNameProps, ChildrenProps {
  sm: number;
  md: number;
  lg: number;
  xl: number;
  heading: ReactNode;
  slideClassName?: string;
}

// =================================================================

export const Slider = (props: SliderProps) => {
  const { sm, md, lg, xl, heading, className, children, slideClassName } = props;

  const previousSlideButtonRef = useRef<HTMLButtonElement | null>(null);
  const nextSlideButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <div className={cn(styles.slider, className)}>
      <div className={styles.topPanel}>
        <SectionHeading>{heading}</SectionHeading>
        <div className={styles.sliderNav}>
          <IconButton
            as="button"
            ref={previousSlideButtonRef}
            icon={FatChevronSVG}
            iconWidth={10}
            iconHeight={12}
            className={styles.previousArrow}
          />
          <IconButton
            as="button"
            ref={nextSlideButtonRef}
            icon={FatChevronSVG}
            iconWidth={10}
            iconHeight={12}
            className={styles.nextArrow}
          />
        </div>
      </div>
      <Swiper
        wrapperTag="ul"
        className={styles.swiperContainer}
        spaceBetween={18}
        navigation={{
          prevEl: previousSlideButtonRef.current,
          nextEl: nextSlideButtonRef.current,
        }}
        mousewheel={{ forceToAxis: true }}
        onBeforeInit={swiper => {
          if (swiper.params.navigation && typeof swiper.params.navigation !== 'boolean') {
            if (previousSlideButtonRef.current && nextSlideButtonRef.current) {
              swiper.params.navigation.prevEl = previousSlideButtonRef.current;
              swiper.params.navigation.nextEl = nextSlideButtonRef.current;
            }
          }
        }}
        breakpoints={{
          0: {
            slidesPerView: sm,
            slidesPerGroup: sm,
          },
          576: {
            slidesPerView: md,
            slidesPerGroup: md,
          },
          768: {
            slidesPerView: lg,
            slidesPerGroup: lg,
          },
          992: {
            slidesPerView: xl,
            slidesPerGroup: xl,
          },
        }}
      >
        {React.Children.map(children, (child, index) => (
          <SwiperSlide tag="li" key={index} className={slideClassName}>
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
