import { breakpoints } from '@/app.config';

import type { WebGenres } from '@/api/types/home.types';

import { ForwardedLink } from '@/components/ForwardedLink';
import { Image } from '@/components/Image';

import styles from '@/views/HomeView/HomeGenres/HomeGenreCard.module.scss';

interface HomeGenreCardProps {
  genre: WebGenres;
}

export const HomeGenreCard = (props: HomeGenreCardProps) => {
  const { genre } = props;

  const { genre_icon, genre_id, title } = genre;

  return (
    <ForwardedLink href={`/genre/${genre_id}`} className={styles.homeGenreCard}>
      <div className={styles.genreIcon}>
        <Image
          aspectRatio={96 / 96}
          src={genre_icon}
          alt={title}
          fill
          sizes={`62.5vw, ${breakpoints.small} 50vw`}
        />
      </div>
      <p>{title}</p>
    </ForwardedLink>
  );
};
