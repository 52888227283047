import { useQuery } from '@tanstack/react-query';

import { OnlineTvApi } from '@/api/domains/online-tv.api';

export const useHomeTvList = () => {
  const {
    data: channels = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['home-tv-list'],
    queryFn: OnlineTvApi.fetchHomeTvList,
    staleTime: Infinity,
  });

  return { channels, isLoading, refetch };
};
