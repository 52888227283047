/* eslint-disable @next/next/no-img-element */
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Card, CardBody, CardTitle, CardSubtitle } from '@/components/Card';
import styles from '@/components/MovieCard/MovieCardSkelton.module.scss';

const CARD_ASPECT_RATIO = 333 / 500;

export const MovieCardSkelton = () => {
  return (
    <Card>
      <div style={{ paddingBottom: `${100 / CARD_ASPECT_RATIO}%` }} className={styles.skeltonImage}>
        <img src="/assets/images/movie-card-placeholder.png" alt="movie-card-skelton" />
      </div>
      <CardBody>
        <CardTitle>
          <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={120}>
            <Skeleton />
          </SkeletonTheme>
        </CardTitle>
        <CardSubtitle>
          <SkeletonTheme baseColor="#0F0F12" highlightColor="#444" width={90}>
            <Skeleton />
          </SkeletonTheme>
        </CardSubtitle>
      </CardBody>
    </Card>
  );
};
