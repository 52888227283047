import clsx from 'clsx';

import type { ClassNameProps } from '@/types/common.types';

import styles from '@/components/ProgressBar/ProgressBar.module.scss';

interface ProgressBarProps extends ClassNameProps {
  variant?: 'light' | 'signature';
  max: number;
  value: number;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { variant = 'light', className, value, max } = props;

  const percentage = Math.min((value * 100) / max, 100);

  return (
    <div className={clsx(styles.progress, className, styles[variant])}>
      <div style={{ width: `${percentage}%` }} className={styles.progressInner} />
    </div>
  );
};
