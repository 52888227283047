/* eslint-disable @next/next/no-img-element */
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { CardBody, CardSubtitle, CardTitle } from '@/components/Card';

import styles from '@/views/HomeView/UnfinishedMovieList/UnfinishedMovieCard.module.scss';

const CARD_ASPECT_RATIO = 210 / 118;

export const UnfinishedMovieCardSkelton = () => {
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        <div
          style={{ paddingBottom: `${100 / CARD_ASPECT_RATIO}%` }}
          className={styles.skeltonImageWrapper}
        >
          <img src="/assets/images/watching-card-placeholder.png" alt="watching-card-skelton" />
        </div>
        <CardBody>
          <CardTitle>
            <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={180}>
              <Skeleton className={styles.genreSkelton} />
            </SkeletonTheme>
          </CardTitle>
          <CardSubtitle>
            <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={90}>
              <Skeleton className={styles.genreSkelton} />
            </SkeletonTheme>
          </CardSubtitle>
        </CardBody>
      </div>
    </div>
  );
};
