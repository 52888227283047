import { Container } from 'react-bootstrap';

import { Slider, type SliderProps } from '@/components/Slider';

export const HomeContentBaseSlider = (props: Partial<SliderProps>) => {
  const { children, heading, sm = 2, md = 3, lg = 4, xl = 6, ...rest } = props;
  return (
    <Container className="mb-5 p-0">
      <Slider sm={sm} md={md} lg={lg} xl={xl} heading={heading} {...rest}>
        {children}
      </Slider>
    </Container>
  );
};
