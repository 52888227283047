import { InfiniteLoaderData, PaginationParams } from '@/api/types/common.types';
import type {
  HomeCollectionsResponse,
  UnfinishedMovie,
  WebGenres,
  HomeCollectionList,
} from '@/api/types/home.types';
import type { MovieCollection, Movie } from '@/api/types/movie.types';

import { httpClient } from '@/libs/http-client';

export class HomeApi {
  static collections = async (params: PaginationParams) => {
    const { data } = await httpClient.get<HomeCollectionsResponse>('/home/movie-collections', {
      params,
    });

    const transformedData: InfiniteLoaderData<HomeCollectionList, unknown> = {
      currentPage: data.currentPage,
      lastPage: data.lastPage,
      total: data.total,
      list: data.list,
    };

    return transformedData;
  };

  // =================================================================

  static extractCollectionMovies = (movies: MovieCollection[]): Movie[] => {
    return movies.map(movie => ({
      id: movie.id,
      card_type: movie.cardType,
      age_limit: movie.age_limit,
      genres: movie.genres,
      is_free: movie.is_free,
      is_new: movie.is_new,
      is_serial: movie.is_serial,
      module_id: movie.moduleId,
      poster: movie.poster,
      quality: movie.quality,
      title: movie.title,
      title_en: movie.title_en,
      year: movie.year,
    }));
  };

  // =================================================================

  static fetchUnfinishedMovies = async () => {
    const { data } = await httpClient.get<UnfinishedMovie[]>('/home/last-position');

    return data;
  };

  // =================================================================

  static fetchHomeGenres = async () => {
    const { data } = await httpClient.get<WebGenres[]>('/home/genres');

    return data;
  };
}
