'use client';

import { useEffect } from 'react';

import { useTranslations } from 'next-intl';

import { moduleTypes } from '@/app.config';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useHomeTvList } from '@/views/HomeView/OnlineTvList/useHomeTvList';

import { ForwardedLink } from '@/components/ForwardedLink';

import { HomeContentBaseSlider } from '@/views/HomeView/HomeContentBaseSlider';
import { OnlineTvCard } from '@/views/HomeView/OnlineTvList/OnlineTvCard';
import { OlineTvCardSkelton } from '@/views/HomeView/OnlineTvList/OnlineTvCardSkelton';
import styles from '@/views/HomeView/OnlineTvList/OnlineTvList.module.scss';

export const OnlineTvList = () => {
  const t = useTranslations('page.home');

  const { userAccessToken } = useVerification();

  const { refetch, channels, isLoading } = useHomeTvList();

  useEffect(() => {
    if (userAccessToken) {
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccessToken]);

  return (
    <HomeContentBaseSlider
      sm={3}
      md={4}
      lg={6}
      xl={8}
      heading={<ForwardedLink href={`/${moduleTypes.onlineTv}`}>{t('onlineTv')}</ForwardedLink>}
      className={styles.onlineTvList}
      slideClassName={styles.onlineTvListSlide}
    >
      {isLoading
        ? Array(8)
            .fill(null)
            .map((_, index) => <OlineTvCardSkelton key={index} />)
        : channels.map(channel => (
            <OnlineTvCard
              key={channel.id}
              channel={channel}
              isAuthenticated={Boolean(userAccessToken)}
            />
          ))}
    </HomeContentBaseSlider>
  );
};
