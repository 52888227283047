import { useQuery, useQueryClient } from '@tanstack/react-query';

import { produce } from 'immer';
import { useLocale } from 'next-intl';

import { Locale } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { HomeApi } from '@/api/domains/home.api';
import { UnfinishedMovie } from '@/api/types/home.types';

import { useNetwork } from '@/contexts/NetworkContext';
import { HomeQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useUnfinishedMovies = () => {
  const { userAccessToken } = useVerification();
  const { isTasix } = useNetwork();

  const locale = useLocale() as Locale;

  const isEnable = Boolean(userAccessToken && isTasix);

  const {
    data = [],
    isLoading,
    status,
  } = useQuery({
    queryKey: HomeQueryKeys.unfinshedMovies(locale),
    queryFn: HomeApi.fetchUnfinishedMovies,
    enabled: isEnable,
    staleTime: 0,
    retry: false,
  });

  return { movies: data, isLoading, isEnable, status };
};

export const useInvalidateUnfinishedMovies = (movieId: number) => {
  const queryClient = useQueryClient();
  const locale = useLocale() as Locale;
  
  return () => {
    const unfinishedMoviesKacheKey = HomeQueryKeys.unfinshedMovies(locale);

    queryClient.setQueryData(unfinishedMoviesKacheKey, (old: UnfinishedMovie[]) => {
      return produce(old, draft => {
        const removedMovieIndex = draft.findIndex(
          unfinishedMovie => unfinishedMovie.movieId === movieId,
        );

        if (removedMovieIndex !== -1) {
          draft.splice(removedMovieIndex, 1);
        }
      });
    });
  };
};
