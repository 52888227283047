import { bannerLinkTypes } from '@/app.config';
import { Link } from '@/navigation';

import { Banner } from '@/api/types/home.types';

import { Image } from '@/components/Image';

import styles from '@/views/HomeView/BannerSlider/BannerSlide.module.scss';

// =================================================================

const BANNER_ASPECT_RATIO = 1296 / 570;

// =================================================================

interface BannerSlideProps {
  banner: Banner;
}

// =================================================================

export const BannerSlide = (props: BannerSlideProps) => {
  const { banner } = props;
  const { moduleId, linkType, link, bannerPhoto } = banner;

  const isLinkTypeOfCollection = linkType === bannerLinkTypes.collection;
  const isLinkTypeOfExternal = linkType === bannerLinkTypes.externalLink;

  let url: string;
  if (isLinkTypeOfExternal) {
    url = link;
  } else if (isLinkTypeOfCollection) {
    url = '';
  } else {
    url = `/watch/${moduleId}/${link}`;
  }

  return (
    <Link href={url} prefetch={false} tabIndex={-1}>
      <Image
        aspectRatio={BANNER_ASPECT_RATIO}
        className={styles.bannerImage}
        src={bannerPhoto}
        alt={'home-banner'}
        quality={100}
        fill
      />
    </Link>
  );
};
